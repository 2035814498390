import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import GenaiCreateChatInterface from './GenaiCreateChatInterface';
function App() {
  const [count, setCount] = useState(0);
  
  useEffect(() => {
    document.title = `You clicked ${count} times`;
  }, [count]); // Only re-run the effect if count changes
  
  return (
    <div>
      <p>You clicked {count} times</p>
      <button onClick={() => setCount(count + 1)}>
        Click me
      </button>
    <GenaiCreateChatInterface renderingSource={""} entryComponent={false} selectedApp={false}/>
    </div>
  );
}

const container = document.getElementById("root");
const root = ReactDOM.createRoot(container);
root.render(<App />);
