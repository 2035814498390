import React from 'react';
import * as antd from "antd";

const { useState, useEffect } = React;
const { Spin   } = antd;

const SpinnerComponent = () => {
    //
    const antIcon = (
        <div className="three-dots-spinner">
            <div className="dot"></div>
            <div className="dot"></div>
            <div className="dot"></div>
        </div>
    );

    return (
        antIcon
    );
};

export default SpinnerComponent;
