import React from 'react';
import * as antd from "antd";
import * as antdIcons from "@ant-design/icons";
import markdownIt from "markdown-it";
import { fetchEventSource } from "@microsoft/fetch-event-source";
import SpinnerComponent from "./SpinnerComponent";

const { useState, useEffect } = React;
const {
  Input,
  Button,
  Upload,
  Modal,
  Layout,
  Menu,
  message,
  Radio,
  Dropdown,
  Checkbox,
  Divider,
  Typography,
  Space,
  Progress,
  Alert,
  Switch,
  Tabs,
  Splitter,
  Flex,
} = antd;
const {
  UploadOutlined,
  ArrowUpOutlined,
  CodeOutlined,
  HistoryOutlined,
  CopyOutlined,
  DownloadOutlined,
  EyeOutlined,
  ExportOutlined,
  PlusOutlined,
  EditOutlined,
  MessageOutlined,
  UserSwitchOutlined,
  SettingOutlined,
  ReloadOutlined,
  DeleteOutlined,
  FileTextOutlined,
  CloseOutlined,
  FullscreenOutlined,
  FullscreenExitOutlined,
  MenuUnfoldOutlined,
  MenuFoldOutlined,
} = antdIcons;

const { TextArea } = Input;
const { Sider, Content } = Layout;
const CheckboxGroup = Checkbox.Group;
const { Text } = Typography;

// Constants
const PLAIN_OPTIONS = [
  { label: "Notify", value: "notify" },
  { label: "Create App", value: "createApp" },
  { label: "Update File", value: "updateFile" },
  { label: "DB Tool", value: "dbTool" },
];

const DEFAULT_CHECKED_LIST = [];

const SIDEBAR_BUTTON_STYLE = {
  width: "100%",
  textAlign: "left",
  padding: "8px 12px",
  color: "#fff",
  height: "auto",
  display: "flex",
  alignItems: "center",
  gap: "8px",
};

// Markdown Configuration
const markdown = markdownIt({
  html: false,
  typographer: true,
  highlight: function (code, lang = "javascript") {
    lang = "javascript";
    const uuid = uuidv4();
    if (lang && hljs.getLanguage(lang)) {
      try {
        return `<pre style="background-color: #2d2d2d;color: #f0f0f0;padding: 10px;border-radius: 5px;" id="codeBlock">${hljs.highlight(code, { language: lang, ignoreIllegals: true }).value
          } </pre>`;
      } catch (__) { }
    }

    return (
      `<div class="whitespace-pre-line w-full rounded-lg bg-black-900 px-4 pb-4 relative font-mono font-normal text-sm text-slate-200">
        <div class="w-full flex items-center absolute top-0 left-0 text-slate-200 bg-stone-800 px-4 py-2 text-xs font-sans justify-between rounded-t-md">
          <div class="flex gap-2"><code class="text-xs"></code></div>
          <button data-code-snippet data-code="code-${uuid}" class="flex items-center gap-x-2">
            <svg stroke="currentColor" fill="none" stroke-width="2" viewBox="0 0 24 24" stroke-linecap="round" stroke-linejoin="round" class="h-4 w-4" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M16 4h2a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h2"></path><rect x="8" y="2" width="8" height="4" rx="1" ry="1"></rect></svg>
            <p>Copy code</p>
          </button>
        </div>
      <pre class="whitespace-pre-wrap">` +
      HTMLEncode(code, "javascript") +
      "</pre></div>"
    );
  },
});

// Main Component
const GenaiCreateChatInterface = ({
  renderingSource,
  selectedApp,
  entryComponent,
}) => {
  const [collapsed, setCollapsed] = useState(true);
  //const apiService = new ob.M.ApiService();
  const apiService = {
    clearChatHistory: ()=>{},
    getTokensConsumedBySessionId: ()=>{}
  };
  // State Management
  const [selectedAiOperation, setSelectedAiOperation] = useState({});
  const [chatMetaData, setChatMetaData] = useState({});
  const [currentSessionId, setCurrentSessionId] = useState("");
  const [isHover, setHover] = useState({});
  const [messageApi, contextHolder] = message.useMessage();
  const [layoutClasses, setLayoutClasses] = useState(
    "chat-container fullscreen",
  );
  const [showPreview, setShowPreview] = useState(false);
  const [newChat, setNewChat] = useState(true);
  const [messages, setMessages] = useState([]);
  const [messageText, setMessageText] = useState("");
  const [fileList, setFileList] = useState([]);
  const [disableChat, setDisableChat] = useState(false);
  const [fileProgress, setFileProgress] = useState({});
  const [persona, setPersona] = useState("Developer");
  const [open, setOpen] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);
  const [history, setHistory] = useState([]);
  const [checkedList, setCheckedList] = useState(DEFAULT_CHECKED_LIST);
  const [appComponents, setAppComponents] = useState([]);

  const [pastedFileName, setPastedFileName] = useState(null);
  const [originalPastedText, setOriginalPastedText] = useState("");
  const [isPastePreviewOpen, setIsPastePreviewOpen] = useState(false);

  // Add these handlers for modal interaction
  const showPastePreview = () => setIsPastePreviewOpen(true);
  const hidePastePreview = () => setIsPastePreviewOpen(false);

  // Utility Functions
  const copyCodeModern = async () => {
    try {
      const codeBlock = document.getElementById("codeBlock");
      // const successMessage = document.getElementById("successMessage");

      await navigator.clipboard.writeText(codeBlock.textContent);
      // console.log("copyCodeModern");
      messageApi.open({
        type: "success",
        content: "Code Copied",
        duration: 10,
      });
      /* successMessage.style.display = "inline";
    setTimeout(() => {
      successMessage.style.display = "none";
    }, 2000); */
    } catch (err) {
      console.error("Failed to copy text:", err);
    }
  };

  const uid = function () {
    return Date.now().toString(36) + Math.random().toString(36).substr(2);
  };

  const handleFile = (fileInfo) => {
    // console.log({ e });
    let file = fileInfo.file;
    const tempFileList = [...fileList, file];
    setFileList([...fileList, file]);
    let upload = new tus.Upload(file, {
      endpoint: "https://snowy-dew-06af.obto.workers.dev", // URL to your Tus server endpoint
      // endpoint: 'http://localhost:43000/tus',
      retryDelays: [0, 1000, 3000, 5000], // Retry delays in milliseconds
      chunkSize: 100 * 1024 * 1024,
      uploadSize: file.size,
      metadata: {
        filename: file.name,
        filetype: file.type,
        filesize: file.size,
        collection: "notification",
        document: "global",
      },
      headers: {
        Filename: file.name,
        Authorization:
          "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX25hbWUiOiJydmFpZCIsIl9pZCI6IjVmYjg5MDE5YmQwMzJhZmNmYWM3ZWQ0MiIsInJvbGVzIjoib2FkLHN1cGVyLGFkbWluLGl0aWwsZnVsZmlsbGVyLGRlbGV0ZSxzY2hvb2xfYWRtaW4sdGVhY2hlcixzdHVkZW50LHByaW5jaXBhbCxmZWUsZmVlX29mZmljZXIsZmVlX2FkbWluLGN0bSxjcm0sY2hhdCxzdXBwb3J0X2FkbWluLGRldmVsb3BlciIsIm5hbWUiOiJEaXZ5IFZlcm1hIiwiZG9tYWluIjoiZGV2IiwiaG9tZXBhZ2UiOiI1ZmQ3YTFlYzNkMGE3NjQyYzQzY2NkZjgiLCJtb2JpbGVfbnVtYmVyIjoiOTMxMDAwNDA0MyIsInRpbWVfem9uZSI6IkFzaWEvS29sa2F0YSIsImlhdCI6MTY5MjY3MTMwOSwiZXhwIjoxNjkyNzA3MzA5fQ.6dEtHG6zcXMRvz5NkbBV2-0X0MX8vOE3wzvcYIz3z3w",
      },
      onError: (error) => {
        console.error("Upload failed:", error);
      },
      onProgress: (bytesUploaded, bytesTotal) => {
        const percentage = ((bytesUploaded / bytesTotal) * 100).toFixed(2);
        /* let progress = {}
        progress.value = percentage; */
        let tempFileProgress = { ...fileProgress };
        tempFileProgress[file.name] = percentage;
        setFileProgress(tempFileProgress);
        console.log(`Upload progress: ${percentage}%`);
      },
      onSuccess: () => {
        console.log("Upload complete");
        // source.src = "https://npsdgd.obto.co/ms/video.bto?n=" + file.name;
        // video.load();
        //
      },
    });

    // Check if there are any previous uploads to continue.
    upload.findPreviousUploads().then(function (previousUploads) {
      // Found previous uploads so we select the first one.
      if (previousUploads.length) {
        upload.resumeFromPreviousUpload(previousUploads[0]);
      }

      // Start the upload
      upload.start();
    });
  };

  const sidebarButtonStyle = {
    width: "100%",
    textAlign: "left",
    padding: "8px 12px",
    color: "#fff",
    height: "auto",
    display: "flex",
    alignItems: "center",
    gap: "8px",
  };

  const bottomNavStyle = {
    position: "relative",
    bottom: 0,
    left: 0,
    //right: 0,
    padding: "5px",
    background: "#1a1a1a",
    borderTop: "1px solid #333",
  };

  // Chat History Management
  const addToChatHistory = (promptMessage) => {
    // console.log({promptMessage})
    let chatHistory = localStorage.getItem("chatHistory");
    chatHistory = chatHistory ? JSON.parse(chatHistory) : {};
    chatHistory[currentSessionId] = { text: promptMessage.text, messages };
    localStorage.setItem("chatHistory", JSON.stringify(chatHistory));
  };

  // Effects
  useEffect(() => {
    setCurrentSessionId(uid());
    let chatHistory = localStorage.getItem("chatHistory");
    if (chatHistory) {
      chatHistory = JSON.parse(chatHistory);
      let localStorageHistory = Object.keys(chatHistory).map(
        (chatSessionid) => ({
          sessionId: chatSessionid,
          name: chatHistory[chatSessionid].text,
        }),
      );
      setHistory(localStorageHistory);
    } else {
      setHistory([]);
    }
  }, []);

  useEffect(() => {
    if (messages.length) {
      addToChatHistory(messages[0]);
    }
  }, [messages]);

  // Event Handlers
  const handleTextChange = (e) => setMessageText(e.target.value);

  const handlePaste = (e) => {
    const pastedText = e.clipboardData.getData("text");
    const lineCount = pastedText.split("\n").length;

    if (lineCount > 15) {
      // Prevent the default paste into textarea
      e.preventDefault();
      // Store large pasted text separately
      setOriginalPastedText(pastedText);
      setPastedFileName(`Pasted Text (${lineCount} lines)`);
    }
    // If less than 15 lines, let default paste behavior occur
  };

  const handleFileChange = (info) => console.log({ fileList });

  const handleCancel = () => setOpen(false);
  const handleNewChat = () => {
    setCurrentSessionId(uid());
    setNewChat(true);
    setMessages([]);
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleSend();
    }
  };

  const handleDropDownClick = (event) => {
    setPersona(event.key);
  };

  const setFullScreen = (value) => {
    setLayoutClasses(value ? "chat-container fullscreen" : "chat-container");
  };

  const onChange = (list) => {
    setCheckedList(list);
  };

  // Message Processing
  const identifyContentType = (text) => {
    if (text.includes("<pre") && text.includes("</pre>")) {
      return "code";
    }
    if (/<\/?[a-z][\s\S]*>/i.test(text)) {
      return "html";
    }
    return "text";
  };

  const setPromptHistory = (sessionId) => {
    console.log(sessionId);
    setCurrentSessionId(sessionId);
    let chatHistory = localStorage.getItem("chatHistory");
    if (chatHistory) {
      chatHistory = JSON.parse(chatHistory);
      console.log({ sessionId, chatHistory });
      setMessages(chatHistory[sessionId].messages);
    }
  };

  // SSE Handling
  const startSSE = async (value) => {
    let currentText = "";
    const ctrl = new AbortController();

    await fetchEventSource(`/ms/streamllm.bto`, {
      method: "POST",
      body: JSON.stringify({
        message: value,
        appName: selectedApp,
        sessionId: currentSessionId,
        aiOperation: selectedAiOperation
      }),
      signal: ctrl.signal,
      openWhenHidden: true,
      async onopen(response) {
        if (response.ok) {
          return;
        } else if (
          response.status >= 400 &&
          response.status < 500 &&
          response.status !== 429
        ) {
          ctrl.abort();
          throw new Error("Invalid Status code response.");
        } else {
          ctrl.abort();
          throw new Error("Unknown error");
        }
      },
      async onmessage(msg) {

        try {
          const chatResult = JSON.parse(msg.data);
          const { message } = chatResult;
          const { content, metaData, done } = message;
          if (!content || typeof content === "undefined") {
            // console.log({ metaData });
            if (metaData) {
              setChatMetaData(metaData);
            }
            return;
          }
          setShowSpinner(false);

          currentText += content;
          setMessages((prevMessages) => {
            const newMessages = [...prevMessages];
            newMessages[prevMessages.length - 1].text = markdown.render(
              currentText,
              "javascript",
            );
            return newMessages;
          });

          if (done) {
            console.log("Completion done.");
            return ctrl.abort();
          }
        } catch { }
      },
      onerror(err) {
        console.log(err);
      },
    });
  };

  // Modify handleSend to use the pasted text if it exists
  const handleSend = () => {
    if (messageText.trim() || originalPastedText || fileList.length > 0) {
      setShowSpinner(true);
      // console.log({ newChat });
      if (newChat) {
        // console.log({ newChat });
        setNewChat(false);
        setHistory([...history, { name: originalPastedText || messageText, sessionId: currentSessionId }]);
      }

      const newQuestion = {
        text: messageText + originalPastedText,
        files: fileList,
        type: "question",
      };

      setMessages((prevMessages) => [
        ...prevMessages,
        newQuestion,
        { text: "", type: "answer", files: [] },
      ]);

      setMessageText("");
      setPastedFileName(null);
      setOriginalPastedText("");
      startSSE(messageText + originalPastedText);
    } else {
      message.error("Please enter a message or upload a file.");
    }
  };

  // Message Handling

  // UI Rendering
  const renderMessageContent = (msg) => {
    const contentType = identifyContentType(msg.text);

    if (msg.type === "question") {
      return <div>{msg.text}</div>;
    }

    if (contentType === "code" || contentType === "html") {
      return <div dangerouslySetInnerHTML={{ __html: msg.text }} />;
    }

    return <div dangerouslySetInnerHTML={{ __html: msg.text }} />;
  };

  // Dropdown Configuration
  const dropdownItems = (
    <Menu onClick={handleDropDownClick}>
      <Menu.Item key="Developer">Developer</Menu.Item>
      <Menu.Item key="Tester">Tester</Menu.Item>
      <Menu.Item key="Admin">Admin</Menu.Item>
      <Menu.Item key="User">User</Menu.Item>
    </Menu>
  );

  const deleteChat = (event, sessionId) => {
    // setCurrentSessionId(sessionId)
    event.stopPropagation();
    // console.log({sessionId});
    let chatHistory = localStorage.getItem("chatHistory");
    if (chatHistory) {
      chatHistory = JSON.parse(chatHistory);
      delete chatHistory[sessionId];
      // setMessages(chatHistory[sessionId].messages);
    }
    let localStorageHistory = Object.keys(chatHistory).map((chatSessionid) => ({
      sessionId: chatSessionid,
      name: chatHistory[chatSessionid].text,
    }));
    setHistory(localStorageHistory);
    localStorage.setItem("chatHistory", JSON.stringify(chatHistory));
  };

  const showDeleteIcon = (sessionId) => {
    const tempIsHover = { ...isHover };
    tempIsHover[sessionId] = true;
    setHover(tempIsHover);
  };

  const hideDeleteIcon = (sessionId) => {
    const tempIsHover = { ...isHover };
    tempIsHover[sessionId] = false;
    setHover(tempIsHover);
  };

  const resetHistory = async () => {
    const result = await apiService.clearChatHistory(currentSessionId);
    // console.log({result});
    messageApi.open({
      type: "success",
      content: "History Reset Successfull",
      duration: 10,
    });
  };

  const renderFileMessage = (fileList) => {
    return fileList.map((file) => {
      return (
        <div style={{ margin: "10px 0" }}>
          <Typography.Text keyboard>{file.name}</Typography.Text>
          <Progress percent={fileProgress[file.name]} strokeColor="#B7EB8F" />
        </div>
      );
    });
  };
  // Component Rendering

  const handleDisableChat = () => {
    if (!disableChat) {
      setDisableChat(true);
    } else {
      setDisableChat(false);
    }
  };

  const handleSwitchChange = (aiOperation, checked) => {
    // if(aiOperation)
    /* const aiOperations = {
      "application_rag": {}
    } */
    let tempSelectedAiOperation = JSON.parse(JSON.stringify((aiOperation)));
    tempSelectedAiOperation = { ...tempSelectedAiOperation, ...aiOperation };
    setSelectedAiOperation(tempSelectedAiOperation);
    // console.log(`switch to ${checked}`);
  };

  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };

  // console.log("history length" + history.length);
  const items = [
    {
      key: "New Chat",
      icon: <PlusOutlined />,
      label: "New Chat",
      onClick: () => {
        handleNewChat();
      },
    },
    {
      key: `${history.length}`, // will help in rerending
      label: "Recent Chats",
      icon: <HistoryOutlined />,
      children: history.map((item, index) => {
        return {
          key: item.sessionId,
          label: (
            <Button
              type="text"
              style={SIDEBAR_BUTTON_STYLE}
              onMouseEnter={() => {
                showDeleteIcon(item.sessionId);
              }}
              onMouseLeave={() => {
                hideDeleteIcon(item.sessionId);
              }}
              onClick={() => {
                setPromptHistory(item.sessionId);
              }}
            >
              {isHover[item.sessionId] ? (
                <DeleteOutlined
                  onClick={(event) => {
                    deleteChat(event, item.sessionId);
                  }}
                />
              ) : (
                <MessageOutlined />
              )}
              {item.name?.substring(0, 15)}
            </Button>
          ),
        };
      }),
    },
    {
      key: "Settings",
      icon: <SettingOutlined />,
      label: "Settings",
      onClick: () => {
        setOpen(true);
      },
    },
  ];
  // return <div>HELLO4</div>;

  const renderAppPreview = () => {
    let Component = "";
    // console.log({entryComponent});
    if (entryComponent && ["ide", "idetabs"].includes(renderingSource)) {
      Component = ob.M[entryComponent];
      // console.log("ob.M[entryComponent] ", Component);
      return (
        <DynamicComponent componentRef={Component} isDev={true} props={{}} />
      );
    }

    return <div></div>;
  };

  const getTokensConsumedBySessionId = async () => {
    try {
      const result = await apiService.getTokensConsumedBySessionId(currentSessionId);
      console.log(result);
    } catch (err) {
      console.log({ err: new Error(err).message });
    }
  }

  const handlePreview = () => {
    setShowPreview(!showPreview);
  };

  const handleOk = () => {
    setOpen(false);
  };

  return (
    <Flex
      style={{
        backgroundColor: "#111",
        height: "100%",
      }}
      className={layoutClasses}
    >
      {contextHolder}
     { !["idetabs"].includes(renderingSource) ? <Layout
        style={{
          padding: "24px",
          paddingBottom: "0",
          backgroundColor: "#111",
        }}
      >
        <Button
          type="primary"
          onClick={toggleCollapsed}
          style={{
            marginBottom: 16,
          }}
        >
          {collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
        </Button>

        <Menu
          mode="inline"
          theme="dark"
          inlineCollapsed={collapsed}
          items={items}
        />
      </Layout> : ""
}
      <Splitter
        style={{
          height: "100%",
          boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
        }}
      >
        <Splitter.Panel defaultSize="50%" min="40%">
          <Layout
            style={{
              padding: "24px",
              paddingBottom: "0",
              backgroundColor: "#111",
              height: "100%",
            }}
          >
            <Modal open={open} onCancel={handleCancel} onOk={handleOk}>
              <Tabs
                defaultActiveKey="1"
                tabPosition={"left"}
                style={{
                  height: 220,
                }}
                items={[
                  {
                    label: `Tools`,
                    key: "tools",
                    children: (
                      <Space direction="vertical">
                        <div>
                          <Switch onChange={handleSwitchChange} /> Update JS
                          File
                        </div>
                        <div>
                          <Switch onChange={handleSwitchChange} /> Update API
                        </div>
                      </Space>
                    ),
                  },
                  {
                    label: `Agents`,
                    key: "agents",
                    children: (
                      <Space direction="vertical">
                        <div>
                          <Switch onChange={handleSwitchChange} /> Update JS
                          File
                        </div>
                        <div>
                          <Switch onChange={handleSwitchChange} /> Update API
                        </div>
                      </Space>
                    ),
                  },

                  {
                    label: `RAG`,
                    key: "rag",
                    children: (
                      <Space direction="vertical">
                        <div>
                          <Switch onChange={() => { handleSwitchChange({ application_rag: true }) }} /> Application RAG
                        </div>
                        <div>
                          <Switch onChange={() => { handleSwitchChange({ file_rag: true }) }} /> File RAG
                        </div>
                      </Space>
                    ),
                  },
                ]}
              />
            </Modal>

            <div
              style={{
                padding: "2%",
                borderRadius: "8px",
                display: "flex",
                flexDirection: "column",
                height: renderingSource === "idetabs" ? "87%" : "100%",
              }}
            >
              <div
                id="chat"
                style={{ flex: 1, overflowY: "auto", marginBottom: "20px" }}
              >
                {!messages.length ? <div
                    style={{
                      padding: "2%",
                      display: "flex",
                      flexDirection: "column",
                      color:"#9c9c9c",
                      alignItems:"center",
                      fontFamily:"monospace",
                      fontSize:"large"
                    }}
                  >
                    Ask anything to OB Assist to improve {selectedApp}
                  </div> : ""}
                {messages.map((msg, index) => (
                  <div
                    key={index}
                    style={{
                      display: "flex",
                      justifyContent:
                        msg.type === "question" ? "flex-end" : "flex-start",
                      marginBottom: "10px",
                    }}
                  >
                    <div
                      style={{
                        backgroundColor:
                          msg.type === "question" ? "#084ca6" : "#1a1a1a",
                        padding: "10px",
                        borderRadius: "8px",
                        maxWidth: "70%",
                        color: "#fff",
                      }}
                    >
                      {index === messages.length - 1 &&
                        showSpinner &&
                        msg.type !== "question" && <SpinnerComponent />}
                      {!msg.files.length ? renderMessageContent(msg) : ""}
                      {msg.files.length ? renderFileMessage(msg.files) : ""}
                      {identifyContentType(msg.text) === "code" && (
                        <Space>
                          <Button
                            type="text"
                            size="small"
                            style={{ color: "#666" }}
                            onClick={copyCodeModern}
                          >
                            <CopyOutlined /> Copy
                          </Button>
                          <Button
                            type="text"
                            size="small"
                            style={{ color: "#666" }}
                          >
                            <DownloadOutlined /> Save
                          </Button>
                          <Button
                            type="text"
                            size="small"
                            style={{ color: "#666" }}
                            onClick={handlePreview}
                          >
                            <EyeOutlined /> Preview
                          </Button>
                        </Space>
                      )}
                    </div>
                  </div>
                ))}
              </div>

              {!disableChat ? (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    backgroundColor: "#1a1a1a",
                    padding: "10px",
                    borderRadius: "8px",
                  }}
                >
                  <Space>
                    <Upload
                      fileList={fileList}
                      onChange={handleFile}
                      beforeUpload={() => false}
                      multiple
                      showUploadList={false}
                      style={{ borderRadius: "50%" }}
                    >
                      <Button
                        type="text"
                        size="small"
                        style={{ color: "#666" }}
                        icon={<UploadOutlined />}
                      >
                        Upload
                      </Button>
                    </Upload>

                    <Dropdown overlay={dropdownItems}>
                      <UserSwitchOutlined
                        style={{ color: "#fff", fontSize: "1em" }}
                      />
                    </Dropdown>

                    <Button
                      type="text"
                      size="small"
                      style={{ color: "#666" }}
                      onClick={resetHistory}
                    >
                      <ReloadOutlined
                        className="ml-2"
                        style={{
                          color: "#fff",
                          fontSize: "1em",
                          cursor: "pointer",
                        }}
                      />
                    </Button>

                    <SettingOutlined style={{
                          color: "#fff",
                          fontSize: "1em",
                          cursor: "pointer",
                        }} onClick={() => {setOpen(true)}} />,
                  </Space>

                  <div
                    style={{
                      flex: 1,
                      marginRight: "10px",
                      marginLeft: "10px",
                      position: "relative", // Added to contain absolute positioned element
                    }}
                  >
                    {pastedFileName && (
                      <>
                        <div
                          style={{
                            position: "absolute",
                            top: "-30px",
                            right: "0",
                            backgroundColor: "#2a2a2a",
                            padding: "4px 8px",
                            borderRadius: "4px",
                            display: "flex",
                            alignItems: "center",
                            gap: "8px",
                            zIndex: 1,
                            cursor: "pointer",
                          }}
                        >
                          <FileTextOutlined style={{ color: "#666" }} />
                          <span
                            style={{ color: "#fff", fontSize: "12px" }}
                            onClick={showPastePreview}
                          >
                            {pastedFileName}
                          </span>
                          <Button
                            type="text"
                            size="small"
                            icon={<CloseOutlined />}
                            style={{ marginLeft: "auto", color: "#666" }}
                            onClick={() => {
                              setPastedFileName(null);
                              setOriginalPastedText("");
                            }}
                          />
                        </div>
                        <Modal
                          title={pastedFileName}
                          open={isPastePreviewOpen}
                          onCancel={hidePastePreview}
                          footer={[
                            <Button key="close" onClick={hidePastePreview}>
                              Close
                            </Button>,
                          ]}
                          width={800}
                          style={{ top: 20 }}
                        >
                          <div
                            style={{
                              maxHeight: "70vh",
                              overflow: "auto",
                              backgroundColor: "#1a1a1a",
                              padding: "16px",
                              borderRadius: "4px",
                              color: "#fff",
                              fontFamily: "monospace",
                              whiteSpace: "pre-wrap",
                              wordWrap: "break-word",
                            }}
                          >
                            {originalPastedText}
                          </div>
                        </Modal>
                      </>
                    )}

                    <TextArea
                      className="obgenai-textarea"
                      rows={1}
                      value={messageText}
                      onChange={handleTextChange}
                      onPaste={handlePaste}
                      placeholder="Type your message..."
                      style={{
                        width: "100%",
                        border: "none",
                        backgroundColor: "#5e5e5e",
                        color: "#fff",
                      }}
                      autoSize={{
                        minRows: 1,
                        maxRows: 10,
                      }}
                      onKeyPress={handleKeyPress}
                    />
                  </div>
                  <Button
                    type="primary"
                    icon={<ArrowUpOutlined />}
                    style={{
                      backgroundColor:
                        messageText.trim() || fileList.length > 0
                          ? "#084ca6"
                          : "grey",
                      color:
                        messageText.trim() || fileList.length > 0
                          ? "#fff"
                          : "white",
                      border: "none",
                      borderRadius: "50%",
                    }}
                    onClick={handleSend}
                    disabled={!messageText.trim() && fileList.length === 0}
                  />
                </div>
              ) : (
                <Alert
                  message="You have reached your token limit."
                  type="error"
                />
              )}

              {persona && !disableChat ? (
                <Flex justify={"space-between"}>
                  <Text style={{ fontSize: 12, color: "#666" }}>
                    {persona} context set. Generative chat will perform actions
                    like a {persona}
                  </Text>
                  <Text style={{ fontSize: 12, color: "#666" }}>
                    Model Used:{chatMetaData.model ? chatMetaData.model : "None"} | Input Token:
                    {chatMetaData.inputTokens ? chatMetaData.inputTokens : 0} |
                    Output Token:
                    {chatMetaData.outputTokens ? chatMetaData.outputTokens : 0}{" "}
                    | Total Tokens:
                    {chatMetaData.totalTokens ? chatMetaData.totalTokens : 0}
                  </Text>
                </Flex>
              ) : (
                ""
              )}
              {!persona && !disableChat ? (
                <Text style={{ fontSize: 12, color: "#666" }}>
                  Press Enter to send, Shift + Enter for new line
                </Text>
              ) : (
                ""
              )}
            </div>
          </Layout>
        </Splitter.Panel>

        {showPreview ? (
          <Splitter.Panel defaultSize="40%" min="20%">
            <Layout
              style={{
                height: "100%",
                width: "95%",
                padding: "1%",
                color: "#fff",
                backgroundColor: "#111",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100px",
                  width: "100%",
                }}
              >
                Preview Coming Soon
              </div>
            </Layout>
          </Splitter.Panel>
        ) : (
          ""
        )}
      </Splitter>
    </Flex>
  );
};

export default GenaiCreateChatInterface;

